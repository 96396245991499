import React, { useState } from "react";
import "./navbar.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [user, setUser] = useState(false);

  const handleLogout = () => {
    setUser(false);
  };
  const handleLogin = () => {
    setUser(true);
  };

  const Menu = () => (
    <>
      <Link to="/">
        <p onClick={() => setToggleMenu(false)}>Menü</p>{" "}
      </Link>
      {/* <p onClick={() => setToggleMenu(false)}>Hakkımızda</p> */}
    </>
  );

  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          {/* <img src={logo} alt="logo" /> */}
          <Link to="/" onClick={() => setToggleMenu(false)}>
            <h1>Orak Cafe</h1>
          </Link>
        </div>
        <div className="navbar-links_container">
          {/* <input type="text" placeholder='Search Item Here' autoFocus={true} /> */}
          <Menu />
          {/* {user && <Link to="/"><p onClick={handleLogout}>Logout</p></Link> } */}
        </div>
      </div>
      <div className="navbar-sign"></div>
      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <Menu />
            </div>
            <div className="navbar-menu_container-links-sign"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
