import React, { useState, useEffect } from "react";
import "./category.css";
import profile_banner from "../../assets/profile_banner.png";
import Contents from "../../components/contents/Contents";
import { getCategoryContents } from "../../app/api";
import { Link, useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const Category = () => {
  const { id } = useParams();

  const [contents, setContents] = useState([]);
  const [category, setCategory] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getCategoryContents(id)
      .then((res) => {
        setContents(res.data.contents);
        console.log(res.data);
        setCategory(res.data.category);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <>
        <div className="categories section__padding">
          <div
            className="categories-container"
            style={{ paddingTop: "5%", paddingBottom: "5%" }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="3"
              animationDuration="0.75"
              width="5%"
              visible={true}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="category section__padding">
      <div className="category-top">
        <div className="category-banner">
          <img src={category?.image_url} alt="banner" />
          <h3>{category?.title}</h3>
        </div>
      </div>
      <div className="category-bottom">
        <Contents
          //title={contents.category.title}
          contents={contents}
          //category={category}
        />
      </div>
    </div>
  );
};

export default Category;
