import React, { useState, useEffect } from "react";
import "./categories.css";
import { Link } from "react-router-dom";
import { getCategories } from "../../app/api";
import { RotatingLines } from "react-loader-spinner";

const Categories = ({ title }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getCategories()
      .then((res) => {
        console.log(res.data.categories);
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <>
        <div className="categories section__padding">
          <div
            className="categories-container"
            style={{ paddingTop: "5%", paddingBottom: "5%" }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="3"
              animationDuration="0.75"
              width="5%"
              visible={true}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="categories section__padding">
      <div className="categories-container">
        <div className="categories-container-text">
          <h1>{title}</h1>
        </div>
        <div className="categories-container-card">
          {categories?.map((item, key) => {
            return (
              <div key={item.id} className="categories-card-column">
                <Link to={`/category/${item.id}`}>
                  <div className="categories-card">
                    <div className="categories-card-top">
                      <img src={item.image_url} alt="" />
                      <p className="categories-title">{item.title}</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Categories;
