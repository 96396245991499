import React from "react";
import "./header.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import profile_banner from "../../assets/profile_banner.png";
import seller1 from "../../assets/seller1.jpg";
import seller2 from "../../assets/seller2.png";
import seller3 from "../../assets/seller3.png";
import seller4 from "../../assets/seller4.png";
import seller5 from "../../assets/seller5.png";
import seller6 from "../../assets/seller6.jpg";
import verify from "../../assets/verify.png";
import bids2 from "../../assets/bids2.png";
import coin from "../../assets/coin.png";
import { Link } from "react-router-dom";
const Header = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="main-banner">
        <img src={profile_banner} alt="banner" />
        <h3>Orak Cafe</h3>
        {/* </div>
      <div className="header section__padding">
        
      <div className="header-slider">
        <h1>Top Sellers</h1>
        <Slider {...settings} className="slider">
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
          <div className="slider-card">
            <Link to={`/profile/Rian`}>
              <div className="slider-img">
                <img src={seller1} alt="" />
              </div>

              <p className="slider-card-name">James Bond</p>
            </Link>
          </div>
        </Slider>
      </div>  */}
      </div>
    </>
  );
};

export default Header;
