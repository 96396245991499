import React, { useEffect, useState } from "react";
import "./item.css";
import creator from "../../assets/seller2.png";
import item from "../../assets/item1.png";
import { RotatingLines } from "react-loader-spinner";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { getContentByID } from "../../app/api";

const Item = () => {
  const { id } = useParams();

  const [contentDetail, setContentDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getContentByID(id)
      .then((res) => {
        setContentDetail(res.data.content);
        console.log(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <>
        <div className="categories section__padding">
          <div
            className="categories-container"
            style={{ paddingTop: "5%", paddingBottom: "5%" }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="3"
              animationDuration="0.75"
              width="5%"
              visible={true}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="item section__padding">
      <div className="item-image">
        <img src={contentDetail.image_url} alt="item" />
      </div>
      <div className="item-content">
        <div className="item-content-title">
          <h1>{contentDetail.title}</h1>
          <p>
            <span>{contentDetail.price} ₺</span>
          </p>
        </div>

        <div className="item-content-detail">
          <p>{contentDetail.descript}</p>
        </div>
      </div>
    </div>
  );
};

export default Item;
