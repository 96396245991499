import axios from "axios";

const API_URL = "https://orakcafe.com/api";

export const GET_CATEGORY_LIST_URL = `${API_URL}/category`;
export const GET_CATEGORY_CONTENT_URL = `${API_URL}/content/category`;
export const GET_CONTENT_URL = `${API_URL}/content`;

export function getCategories() {
  return axios.get(GET_CATEGORY_LIST_URL);
}

export function getCategoryContents(id) {
  return axios.get(`${GET_CATEGORY_CONTENT_URL}/${id}`);
}

export function getContentByID(id) {
  return axios.get(`${GET_CONTENT_URL}/${id}`);
}
