import React, { useState, useEffect } from "react";
import "./contents.css";
import { Link, useParams } from "react-router-dom";
import Masonry from "react-masonry-css";

const Contents = ({ contents }) => {
  const breakpointColumnsObj = {
    default: contents.length >= 4 ? 4 : contents.length,
    1440: contents.length >= 3 ? 3 : contents.length,
    900: contents.length >= 2 ? 2 : contents.length,
  };

  // const breakpointColumnsObj = {
  //   default: 4,
  //   1440: 3,
  //   900: 2,
  // };

  return (
    <div className="contents section__padding">
      <div className="contents-container">
        <div className="contents-container-text">
          <h1>{}</h1>
        </div>
        <div className="contents-container-card">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {contents?.map((item) => {
              return (
                <div key={item.id} className="card-column">
                  <Link to={`/item/${item.id}`}>
                    <div className="contents-card">
                      <div className="contents-card-top">
                        <img src={item.image_url} alt="" />
                      </div>
                      <div className="contents-content">
                        <h1>{item.title}</h1>
                        <h1>{item.price} ₺</h1>
                        <p>{item.descript}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Masonry>
        </div>
      </div>
    </div>
  );
};

export default Contents;
