import React, { useEffect, useState } from "react";
import { Categories, Header } from "../../components";

const Home = () => {
  return (
    <div>
      <Header />
      <Categories title="Kategoriler" />
    </div>
  );
};

export default Home;
