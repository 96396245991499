import React, { useEffect, useState } from "react";
import "./App.css";
import { Navbar, Footer } from "./components";
import { Home, Category, Item, Create, Login, Register } from "./pages";
import { Routes, Route, useLocation } from "react-router-dom";
import { FaAngleUp } from "react-icons/fa";

function App() {
  const location = useLocation();
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 80);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  });

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="top-to-btm">
        {" "}
        {showTopBtn && (
          <FaAngleUp className="icon-position icon-style" onClick={goToTop} />
        )}{" "}
      </div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path=":item/:id" element={<Item />} />
        <Route path="/create" element={<Create />} />
        <Route path="/category/:id" element={<Category />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
